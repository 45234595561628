/**
 * select2.js
 * @link https://select2.org/
 */

import 'jquery';
import 'select2';

$(document).ready(function() {
  $('select[data-filter-select]').select2({
    multiple: true,
    theme: 'ilvo'
  });
});
